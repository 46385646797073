import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`OtoSim Mobile™`}</MDXTag>{` package includes a clip-on phone attachment, a Silicone Earform (right ear only), a functional otoscope for training purposes, a customized suction stand, a customized clamp stand, a leather travel bag, 1-year hardware warranty, and a 3-year software subscription.`}</MDXTag>
<MDXTag name="p" components={components}>{`OtoSim Mobile™ requires a smartphone with internet access to function but is not included in the package.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  