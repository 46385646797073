import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Added functionality:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Automatic advance mode`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Bookmarking capability`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`A continuously updating database`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Activity tracking — Start from where you left off`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Continuous synchronization across multiple devices`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  