import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';
import { Link } from 'gatsby';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Software:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Compatibility: iOS, Android, and the latest smartphone technologies`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Content: Growing library of 400 otologic images with annotations and descriptors`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Quizzes and Exams: Randomized quizzes and exams for self assessing anatomical and diagnostic skills`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Self-directed or in-class learning through `}<Link to="/ophthosim-educators-portal">{`OtoSim Educators Portal™`}</Link></MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  