import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Key software features:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Easy-to-navigate user interface`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Enhanced interactivity with voice-over guidance`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Target touch pad for identifying anatomical features`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Virtual Pneumatic Otoscopy`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Multi language capabilities (English, Mandarin, Japanese)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Search function`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  