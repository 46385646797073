import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Box, Text } from 'grommet';
import Layout from 'components/layout';
import Head from 'components/head';
import Img from 'gatsby-image';
import SectionTitle from 'components/title/sectionTitle';
import Video from 'components/video';
import { RequestBar, RequestBarExtended } from 'components/requestBar';
import { ImageStack } from 'components/pageCards';
import RouteButton from 'components/routeButton';
import Intro from '../../content/products/otosimMobile/intro.mdx';
import BenefitsAndHardware from '../../content/products/otosimMobile/benefitsAndHardware.mdx';
import Software from '../../content/products/otosimMobile/software.mdx';
import Features from '../../content/products/otosimMobile/features.mdx';
import Functionality from '../../content/products/otosimMobile/addedFunctionality.mdx';
import Includes from '../../content/products/otosimMobile/includes.mdx';

const OtosimMobile = ({ data }) => (
  <Layout>
    <Head pageTitle={data.otosimMobileJson.title} />
    <Box fill="horizontal">
      <SectionTitle title="OtoSim Mobile™" />
      <Box width="xlarge" alignSelf="center">
        <Box margin={{ top: 'small', bottom: 'small' }}>
          <Video id="LPg6Ywen_nM" />
        </Box>
        <RouteButton
          to="purchase/otosim-mobile"
          label="Buy Now"
          color="accent-4"
        />
        <RequestBar />
        <ImageStack
          image={data.otosimMobileJson.mobilePagePic1}
          textColor="white"
          textAlign="right"
        >
          <Text size="medium">
            <Intro />
          </Text>
        </ImageStack>
        <ImageStack
          image={data.otosimMobileJson.mobilePagePic2}
          textColor="dark-1"
          textAlign="left"
        >
          <Text size="medium">
            <BenefitsAndHardware />
          </Text>
        </ImageStack>
        <Box background="light-1" pad="medium">
          <Software />
        </Box>
        <Img
          fluid={
            data.otosimMobileJson.mobilePagePic3
              ? data.otosimMobileJson.mobilePagePic3.childImageSharp.fluid
              : {}
          }
          alt="OtoSim Mobile Pic"
        />
        <Box pad="small" background="light-2">
          <Img
            fluid={
              data.otosimMobileJson.mobilePagePic4
                ? data.otosimMobileJson.mobilePagePic4.childImageSharp.fluid
                : {}
            }
            alt="OtoSim Mobile Pic"
          />
        </Box>
        <ImageStack
          image={data.otosimMobileJson.mobilePagePic5}
          textColor="dark-1"
          textAlign="right"
        >
          <Text size="medium">
            <Features />
            <Functionality />
          </Text>
        </ImageStack>
        <Box background="light-1" pad="large">
          <Includes />
        </Box>
        <RouteButton
          to="purchase/otosim-mobile"
          label="Buy Now"
          color="accent-4"
        />
        <RequestBarExtended />
      </Box>
    </Box>
  </Layout>
);

OtosimMobile.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OtosimMobile;

export const query = graphql`
  query OtosimMobileQuery {
    otosimMobileJson {
      title
      mobilePagePic1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobilePagePic2 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobilePagePic3 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobilePagePic4 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobilePagePic5 {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 650, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
